<template>
  <div class="item_preview">
    <div class="box_table">
      <div class="item_cell cell_img">
        <div class="box_img">
          <div class="img_border">
          <img :style="delay" class="img" :src="img" alt="">
          </div>
        </div>
      </div>
      <div class="text_cell">
        <div class="name_block">{{title}}</div>
        <div class="block_text">{{sub_title}}</div>
      </div>
    </div>
  </div>
<!-- require('@/assets/icon/${icons_coins}.svg') -->
</template>
<script>
export default {
  created () {
    this.img = require(`@/assets/icon/${this.imgSrc}.svg`)
  },
  data () {
    return {
      img: '',
      delay: {
        animationDelay: this.delayTime
      }
    }
  },
  props: {
    title: {
      type: String
    },
    delayTime: {
      type: String,
      default: '0s'
    },
    sub_title: {
      type: String
    },
    imgSrc: {
      type: String
    }
  }
}
</script>

<style scoped>
.item_preview{
  width: 50%;
  padding-right: 3em;
  margin-block-start: 3.75em;
  text-align: left;
}
.item_preview::after{
  clear: both;
  content: '';
  display:block;
}
.box_table{
  width: 100%;
  display:  flex;
}
.item_cell{
  display: flex;
  flex-direction: column;
  position: relative;
}
.text_cell{
  padding-top: 2em;
  position: relative;
}
.cell_img{
  width: 140px;
  vertical-align: top;
}
.item_preview .box_img{
  margin-top: 1em;
  display: block;
  padding-right: 1.5em;
}
.name_block .small{
  font-size: 20px;
  margin-bottom: 1em;
  padding-top: 1em;
}
.name_block{
  font-size: 20px;
  color:#033564;
  display: block;
  font-weight: 700;
  line-height: 1.25em;
}
.name_block::before{
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 4px;
  background:#163257;
  content: '';
}
.img_border{
  width: 100px;
  height: 100px;
  border: 1px solid #a0b3c5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img{
  width: 44px;
  height: 47px;
  opacity: 0;
  animation-name: showUp;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes showUp{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.block_text{
  margin-top: 5px;
  font-size: 14px;
}
@media only screen and (max-width: 600px) {
  .item_preview{
    width: 100%;
    padding-right: 0em;
    margin-block-start:1em
  }
}
</style>
